import React from 'react';
import './App.css';
import Testimonial from './classComponents/testimonials';
import Contact from './classComponents/contactMe';
import Experience from './classComponents/experience';
import Footer from './classComponents/footer';
import BlogHome from './classComponents/blogHome';
import BlogAll from './classComponents/allBlogs';
import IndividualBlog from './classComponents/individualBlog';
import Timeline from './classComponents/timeline';
import Search from './classComponents/search';

import { BrowserRouter as Router, Route} from "react-router-dom";

import FunctionalHeader from './functionalComponents/functionalHeader';
import FunctionalSearch from './functionalComponents/functionalSearch';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="AppHeader">
          <FunctionalHeader />
        </header>

        <Route exact path="/" component={HomePage} />

        <Route exact path="/blog" component={allBlogs} />

        <Route exact path="/blog/:blogTitle" component={IndividualBlogPost} />

        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

// Components involved in the home page
function HomePage(){
  return(
    <div>
      <section>
        <FunctionalSearch />
        {/* <Search /> */}
      </section>
      <section>
        <Testimonial />
      </section>
      <section>
        <Experience />
      </section>
      <section>
        <BlogHome />
      </section>
      <section>
        <Timeline />
      </section>
      <section>
        <Contact />
      </section>
    </div>  
  ); 
}

// All Blogs
function allBlogs(){
  return(
    <BlogAll />
  )
}

const IndividualBlogPost = ({ match }) => {
  const { params: { blogTitle } } = match;

  return (
      <IndividualBlog blogID={blogTitle}/>
  );
};

export default App;
