import React, {Component} from 'react';
import paul from '../assets/paulCartright.jpg';

class Testimonial extends Component{

    render(){
        return(
            <div className="TestimonalContainer">
                <div className="TestimonialsCenter">
                    <div className="Person">
                        <img src={paul} className="Paul" alt="Paul Cartwright face" />
                    </div>
                    <div className="PersonText">
                        <p>Scott started in June 2018 as an intern for a year as part of his college course. When Scott started his skill set amazed me for a developer who was still at college. Within the space of a week he had become a valuable part of the team. With in a short space of time he had the knowledge base of a middle weight developer and dealing with clients directly. Scott has a great personality and has been popular with the clients, which is very rare for a developer. He has an excellent knowledge of PHP and MYSQL which has helped him improve his problem-solving skills. He is willing to try new programming languages especially with app development. I will be eagerly watching Scott’s career as I know it will be limitless.</p>
                    </div>
                    <h4>Paul Cartwright - Fire Glass UK</h4>
                </div>
            </div>
        )
    }
}

export default Testimonial;