import React, {Component} from 'react';
import '../App.css';
import client from '../client';


const query = `
    *[_type == 'blog'] | order(Date asc) [0..2]{ 
        _id,
        title, 
        blogtext,
        Date,
        slug,
        "mainImage": mainImage.asset->url
    }
`;



class BlogHome extends Component{
    state = {
        content: []
    };

    initialLoad = async () => {
        const content = await client.fetch(query);
        this.setState({ content, loaded: true });
    };
    
    componentDidMount = async () => {
        if (!this.state.loaded) {
            this.initialLoad();
        }
    };

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render(){
        const {content} = this.state;

        if (!content) return <div>Loading...</div>;

        return(
            <div className="blogContainer">

                <h3 className="blogTitle">My Blog Posts</h3>
     
                <div className="results">
                    {content.map( (content,index) => 
                        <div className="result" key={content._id} id={index} style={{ background: "linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) ), url("+content.mainImage+") no-repeat center"}}>
                            <h3 className="jobTitle">{content.title}</h3>
                            
                            <div className="readMore languageContainer">
                                <a href={'/blog/' + content.slug.current}>Read More</a>
                            </div>
                        </div>
                    )}
                </div>

                <div className="allBlogs">
                    <a href="/blog">All Blog Posts</a>
                </div>

            </div>
        )
    }
}

export default BlogHome;