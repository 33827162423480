import React, {useState, useEffect} from 'react';
import client from '../client';
import WeatherProfile from '../classComponents/weatherPicture';

const query = `
    *[_type == 'category']{ 
        _id,
        language
    }
`;

function HeaderMain(props){
    return (
        <div>
            <div className="TopBar">
                <div className="TopLeft">
                    <WeatherProfile></WeatherProfile>
                    <h2 className="Introduction">{props.introduction}</h2>
                </div>

                <div className="TopRight">
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/scottandrews98"><i className="fab fa-github"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/scottandrews490"><i className="fab fa-twitter"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/scottandrewsuk/"><i className="fab fa-linkedin"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/scott_andrews27/"><i className="fab fa-instagram"></i></a>
                </div>
            </div>

            <div className="ProgrammingLanguages">
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}

// Header functional component to replace the class based component above

function FunctionalHeader(){
    const [textIndex, updateTextIndex] = useState(0);
    const [content, updateContent] = useState([]);

    useEffect(() => {
        var timer = "";

        async function getContent(){
            const content = await client.fetch(query);
            updateContent({content, loaded: true});
        }

        if (!content.loaded) {
            getContent();
        }else{
            timer = setInterval(() => {
                updateTextIndex( textIndex => textIndex + 1 );
            }, 1500);
        }

        return () => clearTimeout(timer);
    })

    if(content.length === 0){
        var textThatChanges = "Loading";
    }else{
        textThatChanges = content.content[textIndex % content.content.length].language;
    } 

    return(
        <div className="HeaderContainer">
            <HeaderMain title={textThatChanges} introduction="Hi, I’m Scott Andrews"/>
        </div>
    )
}

export default FunctionalHeader;