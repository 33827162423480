import React, {useState, useEffect} from 'react';
import client from '../client';
import Moment from 'react-moment';

const query = `
    *[_type == 'jobs']{ 
        _id,
        title, 
        description,
        projectLink,
        Date,
        "mainImage": mainImage.asset->url,
        categories[]->
    }
`;

// TODO fix this as it's firing multiple requests to sanity

// For full project popup
function PopupMain(props){
    return (
        <div className="Overlay">
            <div className="Popup">
                <div className="PopupSplit">
                    <img src={props.mainProjectImage + "?w=300"} className="ProjectImage" alt="Shows what the project looks like" />
                </div>
                <div className="PopupSplit">
                    <button onClick={props.closePopup}>Close</button>
                    <h3 className="popupH3">{props.projectTitle}</h3>
                    <Moment format="MMMM Do YYYY">{props.projectDate}</Moment>
                    <p>{props.description}</p>
                    {(() => {
                        if (props.projectLink) {
                          return (
                            <button><a rel="noopener noreferrer" target="_blank" href={props.projectLink}>View Project</a></button>
                          )
                        } else {
                          return (
                            <div></div>
                          )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}

function FunctionalSearch(){
    // Setting up state values
    const [content, updateContent] = useState({loaded: false});
    const [showPopup, updatePopup] = useState(false);
    const [projectSelected, updateSelected] = useState(0);
    const [popup, setPopup] = useState();

    useEffect(() => {
        async function getContent(){
            const content = await client.fetch(query);
            updateContent({content, loaded: true});
        }

        if(content.loaded === false) {
            getContent();
        }
    })

    function makeBig(event){
        var selectedIndex = 0;

        if(event.currentTarget.id === ""){
            selectedIndex = projectSelected;
        }else{
            selectedIndex = event.currentTarget.id;
        }
        
        const title = content.content[selectedIndex].title;
        const image = content.content[selectedIndex].mainImage;
        const description = content.content[selectedIndex].description;
        const date = content.content[selectedIndex].Date
        const link = content.content[selectedIndex].projectLink;

        const popupNew = <PopupMain mainProjectImage={image} projectTitle={title} projectDate={date} projectLink={link} description={description} closePopup={makeSmall}/>
        setPopup(popupNew);
        updatePopup(true);
        updateSelected(selectedIndex);
    }

    function makeSmall(){
        updatePopup(false);
    }

    // Updates the projects depending on the searched for language
    async function updateSearchContent(searchString){
        if(searchString !== ""){
            var query2 = `
                *[_type == 'jobs' && categories[]->language match "${searchString}"]{ 
                    _id,
                    title, 
                    description,
                    projectLink,
                    Date,
                    "mainImage": mainImage.asset->url,
                    categories[]->
                }
            `;
        }else{
            query2 = query;
        }
        
        const content = await client.fetch(query2);

        if(content.length >= 1){
            updateContent({content, loaded: true});
        }
    }



    if (content.loaded === false) return <div>Loading...</div>;

    return(
        <div className="searchContainer">
            <div className="SearchBox">
                <div className="inputContainer">
                    <input placeholder="Programming Language" className="SearchInput" onChange={function(e) {updateSearchContent(e.target.value)}}/>
                </div>

                <button className="searchButton" onClick={updateSearchContent}>
                    <i className="fas fa-search"></i>
                </button>
            </div>
 
            <div className="results">
                {content.content.map( (content,index) => 
                    <div onClick={makeBig.bind(this)} className="result" key={content._id} id={index} style={{ background: "linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) ), url("+content.mainImage+"?w=400) no-repeat center"}}>
                        <h3 className="jobTitle">{content.title}</h3>

                        <div className="languageContainer">
                            {content.categories.map( (categories, i) => 
                                <div className="individualLanguage" key={content.categories[i]._id}>{content.categories[i].language}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {showPopup ?
                <div>{popup}</div>
                : null
            }
            
        </div>
    )
}

export default FunctionalSearch;