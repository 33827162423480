import React, {Component} from 'react';
import '../App.css';
import client from '../client';
import Moment from 'react-moment';

const query = `
    *[_type == 'jobs']{ 
        _id,
        title, 
        description,
        projectLink,
        Date,
        "mainImage": mainImage.asset->url,
        categories[]->
    }
`;

// For full project popup
function PopupMain(props){
    return (
        <div className="Overlay">
            <div className="Popup">
                <div className="PopupSplit">
                    <img src={props.mainProjectImage + "?w=300"} className="ProjectImage" alt="Shows what the project looks like" />
                </div>
                <div className="PopupSplit">
                    <button onClick={props.closePopup}>Close</button>
                    <h3 className="popupH3">{props.projectTitle}</h3>
                    <Moment format="MMMM Do YYYY">{props.projectDate}</Moment>
                    <p>{props.description}</p>
                    {(() => {
                        if (props.projectLink) {
                          return (
                            <button><a rel="noopener noreferrer" target="_blank" href={props.projectLink}>View Project</a></button>
                          )
                        } else {
                          return (
                            <div></div>
                          )
                        }
                    })()}
                </div>
            </div>
        </div>
    )
}


class Search extends Component{
    state = {
        content: [],
        searchValue: '',
        showPopup: false,
        selected: 0,
    };

    initialLoad = async () => {
        const content = await client.fetch(query);
        this.setState({ content, loaded: true });
    };
    
    componentDidMount = async () => {
        if (!this.state.loaded) {
            this.initialLoad();
        }
    };

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    handleChange = (e) => {
        this.setState({
            searchValue: e.target.value
        },() => {
            this.handleClick();
        });
    }

    handleClick = async () => {

        if(this.state.searchValue !== ""){
            var query2 = `
                *[_type == 'jobs' && categories[]->language match "${this.state.searchValue}"]{ 
                    _id,
                    title, 
                    description,
                    projectLink,
                    Date,
                    "mainImage": mainImage.asset->url,
                    categories[]->
                }
            `;
        }else{
            query2 = query;
        }
        
        const content = await client.fetch(query2);

        if(content.length >= 1){
            this.setState({ content, loaded: true });
        }
    }

    // Makes popup show
    makeBig(event){
        const { content } = this.state;
        var selectedIndex = 0;

        if(event.currentTarget.id === ""){
            selectedIndex = this.state.selected;
        }else{
            selectedIndex = event.currentTarget.id;
        }
        
        const title = content[selectedIndex].title;
        const image = content[selectedIndex].mainImage;
        const description = content[selectedIndex].description;
        const date = content[selectedIndex].Date
        const link = content[selectedIndex].projectLink;

        const popupNew = <PopupMain mainProjectImage={image} projectTitle={title} projectDate={date} projectLink={link} description={description} closePopup={this.makeBig.bind(this)}/>
        this.setState({popup: popupNew, showPopup: !this.state.showPopup, selected: selectedIndex});
    }


    render(){
        const {content} = this.state;

        if (!content) return <div>Loading...</div>;

        return(
            <div className="searchContainer">
                <div className="SearchBox">
                    <div className="inputContainer">
                        <input placeholder="Programming Language" className="SearchInput" onChange={this.handleChange}/>
                    </div>

                    <button className="searchButton" onClick={this.handleClick}>
                        <i className="fas fa-search"></i>
                    </button>
                </div>
     
                <div className="results">
                    {content.map( (content,index) => 
                        <div onClick={this.makeBig.bind(this)} className="result" key={content._id} id={index} style={{ background: "linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) ), url("+content.mainImage+"?w=400) no-repeat center"}}>
                            <h3 className="jobTitle">{content.title}</h3>

                            <div className="languageContainer">
                                {content.categories.map( (categories, i) => 
                                    <div className="individualLanguage" key={content.categories[i]._id}>{content.categories[i].language}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {this.state.showPopup ?
                    <div>{this.state.popup}</div>
                    : null
                }
                
            </div>
        )
    }
}

export default Search;