import React, {Component} from 'react';
import '../App.css';
import client from '../client';
import Moment from 'react-moment';
import BlogText from './blogText';

class IndividualBlog extends Component{
    state = {
        content: []
    };

    initialLoad = async () => {
        const query = `
            *[_type == 'blog' && slug.current == "${this.props.blogID}"]{ 
                _id,
                title, 
                blogtext,
                Date,
                slug,
                "mainImage": mainImage.asset->url,
                newtext
            }
        `;

        const content = await client.fetch(query);
        this.setState({ content, loaded: true });
    };
    
    componentDidMount = async () => {
        if (!this.state.loaded) {
            this.initialLoad();
        }
    };

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render(){
        const {content} = this.state;
        let blogImage = "";

        if(content.length >= 1){
            if(typeof(content[0].mainImage) !== 'undefined'){
                blogImage = <div className='picture' key={content[0]._id} style={{ background: 'url('+content[0].mainImage+') no-repeat center'}}></div>;
            }
        }else{
            blogImage = "";
            return <div>Loading...</div>;
        }
        
        return(
            <div className="searchContainer">
                {content.map( (content,index) => 
    
                    <div className="blogPostContainer" key={content._id}>

                        <div className="backButton">
                            <a href="/blog">Back</a>
                        </div>

                        <h1 className="singleBlogTitle"> {content.title}</h1>

                        {blogImage}

                        <h3 className="dateOfBlog"><Moment format="MMMM Do YYYY">{content.Date}</Moment></h3>
                            
                        <BlogText blocks={ content.newtext }/>
                    </div> 
                )}
            </div>
        )
    }
}

export default IndividualBlog;