import React, {Component} from 'react';
import '../App.css';
import me from '../assets/scottandrews.png';
import clear from '../assets/weather/clear.png';
import rain from '../assets/weather/rain.png';
import cloud from '../assets/weather/cloud.png';
import snow from '../assets/weather/snow.png';

class WeatherProfile extends Component{
    constructor() {
        super();
        this.state = { 
            loaded: false,
            weather: ""
        };
    }

    componentDidMount(){
        fetch("https://scottandrews.dev/.netlify/functions/getWeather")

        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    loaded: true,
                    //weather: result.weather[0].main
                    weather: result.data
                });
                //console.log(result);
            },
            (error) => {
                this.setState({
                    loaded: true,
                    weather: error
                });
            }
        )
    }

    componendDidUnmount(){
        this.subscription.unsubscribe();
    }

    render(){
        const { loaded, weather } = this.state;
        
        var image = me;

        if (loaded === true){
            if(weather === "Rain"){
                image = rain;
            }else if(weather === "Clear"){
                image = clear;
            }else if(weather === "Snow"){
                image = snow;
            }else if(weather === "Clouds"){
                image = cloud;
            }
        }
        
        return(
            <img src={image} className="Me" alt="Scott Andrews Face" />
        )
    }
}

export default WeatherProfile;