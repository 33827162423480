import React, {Component} from 'react';
import '../App.css';

class Footer extends Component{

    render(){
        return(
            <div className="FooterContainer">
                <h4>Built In <a target="_blank" rel="noopener noreferrer" href="https://reactjs.org">React JS</a> With A <a target="_blank" rel="noopener noreferrer" href="https://www.sanity.io">Sanity IO</a> Backend</h4>
                <h5>Scott Andrews 2019</h5>
            </div>
        )
    }
}

export default Footer;

