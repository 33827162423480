// Displays a timeline of my career so far - pulls from sanity

// Import required components 
import React, {Component} from 'react';
import '../App.css';
import client from '../client';
import Moment from 'react-moment';

const query = `
    *[_type == 'timeline' ] | order(dateOfEvent){ 
        _id,
        event,
        dateOfEvent
    }
`;

function LifeEvent(props){
    var direction = ""

    if((props.id + 1) % 2 === 0){
        direction = "right";
    }else{
        direction = "left";
    }

    return (
        <div className={direction}>
            <h4>{props.event} <span className="timelineDivider"></span> <Moment format="MMMM Do YYYY">{props.dateOfEvent}</Moment></h4>
            <div className="middleDot"></div>
        </div>
    )
}

class Timeline extends Component{
    constructor() {
        super();
        this.state = { 
            content: []
        };
    }

    initialLoad = async () => {
        const content = await client.fetch(query);
        this.setState({ content, loaded: true });
    };

    componentDidMount(){
        if (!this.state.loaded) {
            this.initialLoad();
        }
    }

    componendDidUnmount(){
        clearInterval(this.timeout);
        this.subscription.unsubscribe();
    }

    render(){
        const { content } = this.state;
        
        // if (content.length === 0){
        //     var textThatChanges = "Loading Timeline";
        // }else{
        //     textThatChanges = content[this.state.textIdx % content.length].language;
        // } 
        
        return(
            <div>
                <div className="timelineTitleContainer">
                    <h3>Current Experience</h3>
                </div>

                <div className="timeline">
                    {content.map( (content,index) => 
                        <LifeEvent key={content._id} id={index} event={content.event}  dateOfEvent={content.dateOfEvent} />
                    )}
                </div>
            </div>
        )
    }
}

export default Timeline;