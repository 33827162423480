import React, {Component} from 'react';
import '../App.css';

class Contact extends Component{

    render(){
        return(
            <div className="ContactContainer">
                <div className="ContactButtonOutside">
                    <a className="ContactButton" href="mailto:scottandrews490@gmail.com?Subject=Contact%20Me" target="_top">Contact Me!</a>
                </div>
            </div>
        )
    }
}

export default Contact;

