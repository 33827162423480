import React, {Component} from 'react';
import '../App.css';

class Experience extends Component{

    render(){
        return(
            <div className="ExperienceFull">
                <div className="ExpereinceContainer">
                    <div className="Experience">
                        <div className="CircleIcon">
                            <i className="fas fa-award"></i>
                        </div>

                        <div className="TextContent">
                            <h3>On Course For A 1st Class Degree In Computing</h3>
                            <p>I am currently in third year at the University of Worcester and predicted to get a first class degree. I also achieved and academic achievemnt award at the end of second year for being one of the highest achivers in my year.</p>
                        </div>
                    </div>
                    <div className="Experience">
                        <div className="CircleIcon">
                            <i className="fas fa-briefcase"></i>
                        </div>

                        <div className="TextContent">
                            <h3>1 Year Experience In Industry</h3>
                            <p>I undertook a year in industry from June 2018 until June 2019 at Essential Marketer in Bromsgrove UK. This was where I developed many of the projects you see above.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Experience;

