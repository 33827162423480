import BlockContent from '@sanity/block-content-to-react'
import React from 'react'

const serializers = {
  types: {
    iframe: ({node}) => { 
        return <iframe style={{width: "100%", height: "600px"}} src={node.iframeURL} title={node.iframeTitle}></iframe>
    },

    block (props) {
      switch (props.node.style) {
        // Any text that is tagged as being code gets displayed differently 
        case 'code': 
            return <code>{props.node.code}</code>

        default:
            return <p>{props.children}</p>
      }
    },
  }
}

const BlogText = ({ blocks }) => <BlockContent blocks={blocks} serializers={serializers} />

export default BlogText